import { Vue, Component, Watch } from 'vue-property-decorator'
import ChangeLog from '@/modules/common/components/changelog/changelog.vue'
import { mapState } from 'vuex'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import _ from 'lodash'
import { Session, Question } from '@/interfaces'

@Component({
  name: 'GtrSurveyQuestionsView',
  components: {
    changelog: ChangeLog
  },
  computed: {
    ...mapState('sessions', ['sessions']),
    ...mapState('surveys', [
      'sessionChangelog',
      'currentlySessionDeployedLiveUUID',
      'currentSessionDevUUID',
      'sessionSurvey',
      'generalChangelog',
      'currentlyGeneralDeployedLiveUUID',
      'currentGeneralDevUUID',
      'generalSurvey',
      'keynoteChangelog',
      'currentlyKeynoteDeployedLiveUUID',
      'currentKeynoteDevUUID',
      'keynoteSurvey'
    ])
  }
})
export default class GtrSurveyQuestionsView extends Vue {
  // Component state properties.
  allEventSessionQuestions: Question[] = []
  event_uuid = this.$route.params.event_uuid
  loading = false
  changelog: any[] = []
  surveyVersion: string | null = null
  currentDevUUID: string | null = null
  currentlyDeployedLiveUUID: string | null = null

  private questionModel: Question = {
    id: null,
    type: 'radio',
    rate: 0,
    isQuiz: 0,
    range: {
      start: 0,
      end: 0,
      other: ['', '']
    },
    required: 1,
    label: {},
    storage_type: 'text',
    show_if: {
      global_type: '*',
      type: '*',
      field: '',
      operator: '',
      group_operator: '',
      group_items: [],
      value: ''
    },
    speaker_question: 0,
    session_uuid: null,
    correct_answer: null
  }

  // Vuex state properties.
  sessions!: Session[]
  currentlySessionDeployedLiveUUID!: Record<string, any>
  currentSessionDevUUID!: Record<string, any>

  // Lifecycle Hooks

  // on mount check if there are sessions, if no fetch them.
  async mounted (): Promise<void> {
    await this.init()
  }

  // Watchers and Computed Properties.

  // vue reactive seems to be broken in the vuex state for sessionChangelog.
  // I haven't investigated yet, but had to add this for the changelog computed property to work.
  @Watch('generalChangelog')
  @Watch('keynoteChangelog')
  @Watch('sessionChangelog')
  onChangelogChange (payload: any) {
    if (payload.data) {
      this.changelog = payload.data
    }
  }

  @Watch('surveyVersion')
  async onVersionChange (payload: any) {
    if (payload !== 'default') {
      const type = 'surveys/getSessionSurveyByUUID'
      const response = await this.$store.dispatch(type, { event_uuid: this.$route.params.event_uuid, survey_uuid: payload })
      const changeLogPageData = response.data.page_data[0]
      if (changeLogPageData) {
        this.questions = changeLogPageData.fields
        this.currentDevUUID = payload
      }
      Container.get(Notification).success('Survey version successfully changed.')
    }
  }

  @Watch('currentGeneralDevUUID')
  @Watch('currentKeynoteDevUUID')
  @Watch('currentSessionDevUUID')
  onCurrentDevUUIDChange (payload: any) {
    if (payload.dev) {
      this.currentDevUUID = payload.dev.uuid
    }
  }

  @Watch('currentlyGeneralDeployedLiveUUID')
  @Watch('currentlyKeynoteDeployedLiveUUID')
  @Watch('currentlySessionDeployedLiveUUID')
  onCurrentlyDeployedLiveUUID (payload: any) {
    if (payload.live) {
      this.currentlyDeployedLiveUUID = payload.live.uuid
    }
  }

  // Computed Properties
  get changeLogHasRevisions (): boolean {
    return this.changelog.length > 0
  }

  // computed property that returns the number of questions on the page
  get questionCount (): number {
    return this.questions.length
  }

  // computed property sessionHasQuestions returns true if the session has questions, false otherwise
  get sessionHasQuestions (): boolean {
    return this.questions.length > 0
  }

  set questions (questions: Question[]) {
    this.allEventSessionQuestions = questions
  }

  // return the questions from allEventSessionQuestions that match the current session uuid.
  get questions () {
    return this.allEventSessionQuestions
  }

  get activeSessions (): Session[] {
    return this.sessions?.filter(session => session.active) ?? []
  }

  get surveyType () {
    if (this.$route?.name?.includes('general.questions')) {
      return 'General'
    } else if (this.$route?.name?.includes('keynote.questions')) {
      return 'Keynote'
    } else {
      return 'Session'
    }
  }

  // Data Fetchers

  // fetchSessions fetches the sessions from the database. If there is an error, display a notification
  private async fetchSessions () {
    try {
      this.loading = true
      await this.$store.dispatch('sessions/fetchSessions', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false
    }
  }

  private async init () {
    if (this.surveyType === 'Session') {
      await this.fetchSessions()
    }
    await this.fetchSurvey[this.surveyType]()
    await this.fetchChangeLog[this.surveyType]()
    await this.fetchCurrentDevUUID[this.surveyType]()
    await this.fetchCurrentlyDeployedLiveUUID[this.surveyType]()
  }

  /**
   * I chose to use session survey in the page state, which not normally what I do. I may revisit this and use a computed property instead.
   * My rationale is that if you store the session survey in global state it will be available to other components. This is not a problem, but it is not necessary.
   * Actually, currently it gets stored in global state anyway, but I may reach out about changing that. - zsb
   */
  private async fetchGeneralSurvey () {
    try {
      this.loading = true
      const sessionSurvey = await this.$store.dispatch('surveys/getGeneralSurvey', { event_uuid: this.$route.params.event_uuid })
      // check if page_data exists. Also check that is has a length > 0. If it does, set this.questions to the first element in the array, and it's fields property.
      if (sessionSurvey.page_data && sessionSurvey.page_data.length) {
        this.questions = sessionSurvey.page_data[0].fields.map(question => {
          return (question.type === 'checkbox') ? {
            ...question,
            correct_answer: []
          } : question
        })
      }
    } catch (error) {
      if (error.data.error_code !== 'CONTENT_NOT_FOUND') {
        Container.get(ErrorHandlerService).error(error)
      }
    } finally {
      this.loading = false
    }
  }

  // fetch the changelog data.
  private async fetchGeneralChangelog () {
    try {
      this.loading = true
      await this.$store.dispatch('surveys/getGeneralChangelog', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false
    }
  }

  // fetch the current session dev uuid.
  private async fetchCurrentGeneralDevUUID () {
    try {
      this.loading = true
      await this.$store.dispatch('surveys/getCurrentGeneralDevUUID', { event_uuid: this.$route.params.event_uuid, type: 'survey', sub_type: this.surveyType.toLowerCase() })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false
    }
  }

  // fetch the currently session deployed live uuid.
  private async fetchCurrentlyGeneralDeployedLiveUUID () {
    try {
      this.loading = true
      await this.$store.dispatch('surveys/getCurrentlyGeneralDeployedLiveUUID', { event_uuid: this.$route.params.event_uuid, type: 'survey', sub_type: this.surveyType.toLowerCase() })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false
    }
  }

  /**
   * I chose to use session survey in the page state, which not normally what I do. I may revisit this and use a computed property instead.
   * My rationale is that if you store the session survey in global state it will be available to other components. This is not a problem, but it is not necessary.
   * Actually, currently it gets stored in global state anyway, but I may reach out about changing that. - zsb
   */
  private async fetchKeynoteSurvey () {
    try {
      this.loading = true
      const sessionSurvey = await this.$store.dispatch('surveys/getKeynoteSurvey', { event_uuid: this.$route.params.event_uuid })
      // check if page_data exists. Also check that is has a length > 0. If it does, set this.questions to the first element in the array, and it's fields property.
      if (sessionSurvey.page_data && sessionSurvey.page_data.length) {
        this.questions = sessionSurvey.page_data[0].fields.map(question => {
          return (question.type === 'checkbox') ? {
            ...question,
            correct_answer: []
          } : question
        })
      }
    } catch (error) {
      if (error.data.error_code !== 'CONTENT_NOT_FOUND') {
        Container.get(ErrorHandlerService).error(error)
      }
    } finally {
      this.loading = false
    }
  }

  // fetch the changelog data.
  private async fetchKeynoteChangelog () {
    try {
      this.loading = true
      await this.$store.dispatch('surveys/getKeynoteChangelog', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false
    }
  }

  // fetch the current session dev uuid.
  private async fetchCurrentKeynoteDevUUID () {
    try {
      this.loading = true
      await this.$store.dispatch('surveys/getCurrentKeynoteDevUUID', { event_uuid: this.$route.params.event_uuid, type: 'survey', sub_type: this.surveyType.toLowerCase() })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false
    }
  }

  // fetch the currently session deployed live uuid.
  private async fetchCurrentlyKeynoteDeployedLiveUUID () {
    try {
      this.loading = true
      await this.$store.dispatch('surveys/getCurrentlyKeynoteDeployedLiveUUID', { event_uuid: this.$route.params.event_uuid, type: 'survey', sub_type: this.surveyType.toLowerCase() })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false
    }
  }

  /**
   * I chose to use session survey in the page state, which not normally what I do. I may revisit this and use a computed property instead.
   * My rationale is that if you store the session survey in global state it will be available to other components. This is not a problem, but it is not necessary.
   * Actually, currently it gets stored in global state anyway, but I may reach out about changing that. - zsb
   */
  private async fetchSessionSurvey () {
    try {
      this.loading = true
      const sessionSurvey = await this.$store.dispatch('surveys/getSessionSurvey', { event_uuid: this.$route.params.event_uuid })
      // check if page_data exists. Also check that is has a length > 0. If it does, set this.questions to the first element in the array, and it's fields property.
      if (sessionSurvey.page_data && sessionSurvey.page_data.length) {
        this.questions = sessionSurvey.page_data[0].fields.map(question => {
          return (question.type === 'checkbox') ? {
            ...question,
            correct_answer: []
          } : question
        })
      }
    } catch (error) {
      if (error.data.error_code !== 'CONTENT_NOT_FOUND') {
        Container.get(ErrorHandlerService).error(error)
      }
    } finally {
      this.loading = false
    }
  }

  // fetch the changelog data.
  private async fetchSessionChangelog () {
    try {
      this.loading = true
      await this.$store.dispatch('surveys/getSessionChangelog', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false
    }
  }

  // fetch the current session dev uuid.
  private async fetchCurrentSessionDevUUID () {
    try {
      this.loading = true
      await this.$store.dispatch('surveys/getCurrentSessionDevUUID', { event_uuid: this.$route.params.event_uuid, type: 'survey', sub_type: 'session' })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false
    }
  }

  // fetch the currently session deployed live uuid.
  private async fetchCurrentlySessionDeployedLiveUUID () {
    try {
      this.loading = true
      await this.$store.dispatch('surveys/getCurrentlySessionDeployedLiveUUID', { event_uuid: this.$route.params.event_uuid, type: 'survey', sub_type: 'session' })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false
    }
  }

  // Handlers

  // private method backToQuizzes navigates back to the session quizzes view
  async backToQuizzes (): Promise<void> {
    await this.$router.push({ name: 'level-two.modules.evals.surveys.session.index' })
  }

  // private method addQuestion adds a new question object to the questions array
  addQuestion (): void {
    // clone the question model, then set the session_uuid to this sessions uuid.
    const newQuestion: Question = _.cloneDeep(this.questionModel)
    // assign a new uuid to the new question
    newQuestion.id = Vue.prototype.$uuid.v4()
    // set the session_uuid to this session uuid
    // newQuestion.session_uuid = this.session_uuid
    // create a copy of allEventSessionQuestions
    const newAllEventSessionQuestions = _.cloneDeep(this.allEventSessionQuestions)
    // push the new question to the allEventSessionQuestions array
    newAllEventSessionQuestions.push(newQuestion)
    // set allEventSessionQuestions to the new array
    this.questions = newAllEventSessionQuestions
  }

  // TODO: make type for field. - zb
  private checkQuestionRange (field: any) {
    function rangeIsEmpty () {
      return field.range && !field.range.start && !field.range.end && field.range.other.length === 0
    }
    return rangeIsEmpty() || field.type === 'text'
  }

  private checkQuestionsForErrors () {
    const errors = this.allEventSessionQuestions.map(field => {
      return (field.type === 'radio' && field.rate === 1 && field.range.start === field.range.end) || (['radio', 'checkbox'].includes(field.type) && field.rate === 0 && field.range.other.some(a => !a))
    })
    return errors.some(err => err)
  }

  // private method saveQuestions saves the questions array to the database. for now display a notification "Questions have been saved"
  async saveQuestions (): Promise<void> {
    try {
      this.loading = true
      if (this.checkQuestionsForErrors()) {
        Container.get(Notification).error('There are problems with some of your questions. Please review.')
        return
      }
      const fields = this.allEventSessionQuestions.map(field => {
        if (this.checkQuestionRange(field)) {
          delete field.range
        }
        if (field.correct_answer) {
          delete field.rate
        } else {
          delete field.correct_answer
        }

        if (field.session_uuid && field.session_uuid.length === 0) {
          field.session_uuid = null
        }

        return field
      })
      const data = [{
        page_type: 'STANDARD',
        prefix: '',
        suffix: '',
        settings: {},
        fields: fields
      }]

      const dispatchType = `surveys/save${this.surveyType}Survey`
      const sub_type = this.surveyType.toLowerCase()

      await this.$store.dispatch(dispatchType, {
        event_uuid: this.$route.params.event_uuid,
        type: 'survey',
        sub_type,
        data
      })
      Container.get(Notification).success('Questions have been saved')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false
    }
  }

  // private method deleteQuestion deletes a question from the questions array. It does this by filtering out the question by comparing the question object to the question object passed in as a parameter. Must use deep equal to compare objects (lodash)
  deleteQuestion (question_id: string): void {
    this.questions = this.questions.filter(({ id }) => id !== question_id)
  }

  updateSurveyVersion (uuid: string) {
    this.surveyVersion = uuid
  }

  // There is probably a better way to do this.
  fetchSurvey = {
    General: this.fetchGeneralSurvey,
    Keynote: this.fetchKeynoteSurvey,
    Session: this.fetchSessionSurvey
  }

  fetchChangeLog = {
    General: this.fetchGeneralChangelog,
    Keynote: this.fetchKeynoteChangelog,
    Session: this.fetchSessionChangelog
  }

  fetchCurrentDevUUID = {
    General: this.fetchCurrentGeneralDevUUID,
    Keynote: this.fetchCurrentKeynoteDevUUID,
    Session: this.fetchCurrentSessionDevUUID
  }

  fetchCurrentlyDeployedLiveUUID = {
    General: this.fetchCurrentlyGeneralDeployedLiveUUID,
    Keynote: this.fetchCurrentlyKeynoteDeployedLiveUUID,
    Session: this.fetchCurrentlySessionDeployedLiveUUID
  }

  async handleDeployed (): Promise<void> {
    await this.fetchChangeLog[this.surveyType]()
    await this.fetchCurrentDevUUID[this.surveyType]()
    await this.fetchCurrentlyDeployedLiveUUID[this.surveyType]()
  }
}
